import React, { Component } from 'react';
import './App.css';
import Navigation from './Navigation.js'
import Footer from './Footer.js'


class Contact extends Component {
  
  render() {
    return (
        <div>
            <header>
            <img src="/img/progress-bar-generator-logo.png" className="logo" alt="Progress Bar Generator Logo" />
            <a href="/" className="logo-title">Progress Bar Generator</a>
                <nav className="menu">
                    <Navigation />
                </nav>
            </header>
                <div className="section">
                <h1>Contact Us</h1>
                <p>If you have any questions or business inquiries, contact us at <b>contact@progressbargenerator.com</b></p>
                </div>
            <Footer />
         </div>
    );
  }
}

export default Contact;