import React, { Component } from 'react';
import Navigation from './Navigation.js'
import Footer from './Footer.js'
import './App.css';


class About extends Component {
  
  render() {
    return (
      <div>
        <header>
      <img src="/img/progress-bar-generator-logo.png" className="logo" alt="Progress Bar Generator Logo" />
      <a href="/" className="logo-title">Progress Bar Generator</a>
          <nav className="menu">
              <Navigation />
          </nav>
      </header>
         <div className="section">
          <h1>About</h1>
          <div>
            <p><p>We are a passionate team that aims to build easy to use tools that benefit others.
                <br /> Progress Bar Generator was created by developers for developers and non technical users
                that want to quickly create a progress bar.
            </p></p>

            <p>This <b>Progress Bar Generator</b> is a tool that allows you to make a progress bar in seconds.</p>

            <p>You can customize the progress bar according to your needs. You also have the option of making a circular progress bar.
            See a live preview as you make changes and copy the generated HTML and CSS code for your project.</p>
            
            <h2>Click below to see our other generators and tools:</h2>

<p><b>Hamburger Menu Generator</b> - <a href="https://hamburgermenugenerator.com/" target="_blank">hamburgermenugenerator.com</a> </p>

<p><b>CSS Circle Generator</b> - <a href="https://csscirclegenerator.com/" target="_blank">csscirclegenerator.com</a> </p>

<p><b>REM to PX Converter</b> - <a href="https://remtopxconverter.com/" target="_blank">remtopxconverter.com</a> </p>

            <h2>How to Use the Progress Bar Generator</h2>

            <p><b>Step 1:</b> Select a width and height for the progress bar. 
            <br></br> <br></br>The width is a value that is a percentage (%) out of 100 and represents the fill color of the progress bar
            <br></br> The height is a value that is in pixels (px) and is the height of the progress bar. </p>
            <p><b>Step 2: </b>Select a Color
            <br></br><br></br> The background color is the background of the progress bar and you can enter it by selecting a RGB value, 
            HEX value, or the color wheel. The fill color is the color of the progress bar.</p>
            <p><b>Step 3:</b> Customize the Progress Bar <br></br><br>
            </br> You can further customize the progress bar simply by selecting a few options. <br /> <br />
             Add <b>animation</b> to the progress bar by clicking <b>"Show Animation"</b>. The animation takes place as you change the width.
             The default animation is ease but you can add your own animation according to your needs.
             <br></br><br></br>Add a <b>percentage</b> to your loading bar by clicking <b>"Show Percentage"</b>.
             The percentage is shown on the right of the progress bar and changes based on the width.
             <br></br><br></br> Convert the progress bar into a <b>circular progress bar</b> by clicking "Show Circular Progress Bar".</p>
          
             <p><b>Step 4:</b> See the Live Preview <br></br><br></br>
             The preview allows you to see the changes that you've made in real time.</p>

             <p><b>Step 5:</b> Copy the Generated Code <br></br><br></br>
             There is a section where the HTML and CSS code is generated. Copy the generated HTML and CSS
             code and put it in your project to have the progress bar shown in the preview.
              </p> 

          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default About;