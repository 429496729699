import React, { Component } from 'react';
import './App.css';
import Navigation from './Navigation.js'
import Footer from './Footer.js'


class Disclaimer extends Component {
  


  render() {
    return (
        <div>
            <header>
      <img src="/img/progress-bar-generator-logo.png" className="logo" alt="Progress Bar Generator Logo" />
      <a href="/" className="logo-title">Progress Bar Generator</a>
          <nav className="menu">
              <Navigation />
          </nav>
      </header>

        <div className="section">

            <h1>Disclaimer</h1>

            <p>Information on this web site may contain technical inaccuracies or typographical errors.
        Information may be changed or updated without notice. The Site Owner may also make improvements and/or changes in the products and/or the programs described in this information at any time without notice.
        The Site Owner assumes no responsibility regarding the accuracy of the information that is provided on this site and use of such information is at the recipient's own risk. 
        <br></br><br></br>IN NO EVENT WILL THE SITE OWNER BE LIABLE TO ANY PARTY FOR ANY DIRECT, INDIRECT, SPECIAL OR OTHER CONSEQUENTIAL DAMAGES FOR ANY USE OF THIS WEB SITE, OR ON ANY OTHER HYPER LINKED WEB SITE, INCLUDING, WITHOUT LIMITATION, ANY LOST PROFITS, BUSINESS INTERRUPTION, LOSS OF PROGRAMS OR OTHER DATA ON YOUR INFORMATION HANDLING SYSTEM OR OTHERWISE.</p>
            
          
          </div>
          <Footer />
        </div>

    );
  }
}

export default Disclaimer;