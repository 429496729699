
import React from 'react';
import './App.css';
import { Route, Routes} from 'react-router-dom'
import Home from './Home.js'
import About from './About.js'
import Terms from './Terms.js'
import Disclaimer from './Disclaimer.js'
import Privacy from './Privacy.js'
import Contact from './Contact.js'





class App extends React.Component {
  render() {
    return (
      <div>
          <Routes>
            <Route path="/" exact  element={<Home />} />
            <Route path="/about" element={<About />}/>
            <Route path="/contact" element={<Contact />}/>
            <Route path="/terms" element={<Terms/>} />
            <Route path="/disclaimer"  element={<Disclaimer/>} />
            <Route path="/privacy-policy" element={<Privacy/>} />
          </Routes>
      </div>
  
    );
  }
}

export default App;
