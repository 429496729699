import React, { Component } from 'react';
import './App.css';
import Navigation from './Navigation.js'
import Footer from './Footer.js'


class Terms extends Component {
  render() {
    return (
      <div>
        <header>
      <img src="/img/progress-bar-generator-logo.png" className="logo" alt="Progress Bar Generator Logo" />
      <a href="/" className="logo-title">Progress Bar Generator</a>
          <nav className="menu">
              <Navigation />
          </nav>
      </header>

        <div>
            <div className="section">

            <h1 >Terms of Use & Conditions</h1>
            
            <p>These terms and conditions ("Terms", "Agreement") are an agreement between Website Operator ("Website Operator", "us", "we" or "our") and you ("User", "you" or "your"). 
            This Agreement sets forth the general terms and conditions of your use of the
            progressbargenerator.com website and any of its products or services (collectively, "Website" or "Services").</p>

            <h2>Intellectual property rights</h2>
             <p>This Agreement does not transfer to you any intellectual property owned by Website Operator or third-parties, and all rights, titles, and interests in and to such property will remain (as between the parties) solely with Website Operator. All trademarks, service marks, graphics and logos used in connection with our Website or Services, are trademarks or registered trademarks of Website Operator or Website Operator licensors. Other trademarks, service marks, graphics and logos used in connection with our Website or Services may be the trademarks of other third-parties. Your use of our Website and Services grants you no right or license to reproduce or otherwise use any Website Operator or third-party trademarks.</p>
             
             <h2>Limitation of liability</h2>
             <p>To the fullest extent permitted by applicable law, in no event will Website Operator, its affiliates, officers, directors, employees, agents, suppliers or licensors be liable to any person for (a): any indirect, incidental, special, punitive, cover or consequential damages (including, without limitation, damages for lost profits, revenue, sales, goodwill, use of content, impact on business, business interruption, loss of anticipated savings, loss of business opportunity) however caused, under any theory of liability, including, without limitation, contract, tort, warranty, breach of statutory duty, negligence or otherwise, even if Website Operator has been advised as to the possibility of such damages or could have foreseen such damages. To the maximum extent permitted by applicable law, the aggregate liability of Website Operator and its affiliates, officers, employees, agents, suppliers and licensors, relating to the services will be limited to an amount greater of one dollar or any amounts actually paid in cash by you to Website Operator for the prior one month period prior to the first event or occurrence giving rise to such liability. The limitations and exclusions also apply if this remedy does not fully compensate you for any losses or fails of its essential purpose.</p>

             <h2>Links To Other Websites</h2>
             <p>Although this Website may link to other websites, we are not, directly or indirectly, implying any approval, association, sponsorship, endorsement, or affiliation with any linked website, unless specifically stated herein. Some of the links on the Website may be "affiliate links". This means if you click on the link and purchase an item, Website Operator will receive an affiliate commission. We are not responsible for examining or evaluating, and we do not warrant the offerings of, any businesses or individuals or the content of their websites. We do not assume any responsibility or liability for the actions, products, services, and content of any other third-parties. You should carefully review the legal statements and other conditions of use of any website which you access through a link from this Website. Your linking to any other off-site websites is at your own risk.</p>

             <h2>Changes and Amendments</h2>
             <p>We reserve the right to modify this Agreement or its policies relating to the Website or Services at any time, effective upon posting of an updated version of this Agreement on the Website. When we do, we will post a notification on the main page of our Website. Continued use of the Website after any such changes shall constitute your consent to such changes.</p>
            
             <h2>Acceptance of These Terms</h2>
             <p>You acknowledge that you have read this Agreement and agree to all its terms and conditions. By using the Website or its Services you agree to be bound by this Agreement. If you do not agree to abide by the terms of this Agreement, you are not authorized to use or access the Website and its Services.</p>

             <h2>Contacting us</h2>
             <p>If you would like to contact us to understand more about this Agreement or wish to contact us concerning any matter relating to it, you may send an email to contact@progressbargenerator.com</p>
        </div>
    </div>
        <Footer />

        </div>

    );
  }
}

export default Terms;