import React, { useState } from 'react';
import Navigation from './Navigation.js'
import Footer from './Footer.js'
import './App.css';

function Home() {
  const [width, setWidth] = useState(50);
  const [height, setHeight] = useState(20);
  const [bgColor, setBgColor] = useState('#cccccc');
  const [fillColor, setFillColor] = useState('#4caf50');
  const [animate, setAnimate] = useState(false);
  const [animationDuration, setAnimationDuration] = useState('2s');
  const [animationTimingFunction, setAnimationTimingFunction] = useState('ease');
  const [animationDelay, setAnimationDelay] = useState('0s');
  const [showPercentage, setShowPercentage] = useState(false);
  const [circular, setCircular] = useState(false);

  const generateCSS = () => {
    if (circular) {
      return `
/* CSS */
#circularContainer {
  position: relative;
  width: ${height * 2}px;
  height: ${height * 2}px;
  background-color: ${bgColor};
  border-radius: 50%;
  overflow: hidden;
}

#circularSVG {
  transform: rotate(-90deg);
  width: 100%;
  height: 100%;
}

#circularBackground {
  fill: none;
  stroke: ${bgColor};
  stroke-width: ${height};
}

#circularBar {
  fill: none;
  stroke: ${fillColor};
  stroke-width: ${height};
  stroke-dasharray: ${Math.PI * height};
  stroke-dashoffset: ${(1 - width / 100) * Math.PI * height};
  ${animate ? `transition: stroke-dashoffset ${animationDuration} ${animationTimingFunction} ${animationDelay};` : ''}
}

.circularPercentage {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: ${height - 4}px;
  color: ${fillColor};
}
      `;
    } else {
      return `
/* CSS */
#progressContainer {
  width: 100%;
  background-color: ${bgColor};
  border-radius: 5px;
  overflow: hidden;
  position: relative;
}

#progressBar {
  width: ${width}%;
  height: ${height}px;
  background-color: ${fillColor};
  ${animate ? `transition: width ${animationDuration} ${animationTimingFunction} ${animationDelay};` : ''}
}

.percentage {
  position: absolute;
  right: ${100 - width}%;
  top: 50%;
  transform: translateY(-50%);
  font-size: ${height - 4}px;
  color: white;
  padding-right: 5px;
}
      `;
    }
  };

  const generateHTML = () => {
    if (circular) {
      return `
<!-- HTML -->
<div id="circularContainer">
  <svg id="circularSVG">
    <circle id="circularBackground" cx="${height}" cy="${height}" r="${height}" />
    <circle id="circularBar" cx="${height}" cy="${height}" r="${height}"
            style="stroke-dasharray: ${Math.PI * height}; stroke-dashoffset: ${(1 - width / 100) * Math.PI * height}; ${animate ? `transition: stroke-dashoffset ${animationDuration} ${animationTimingFunction} ${animationDelay};` : ''}" />
  </svg>
  ${showPercentage ? `<span class="circularPercentage">${width}%</span>` : ''}
</div>
      `;
    } else {
      return `
<!-- HTML -->
<div id="progressContainer" style="background-color: ${bgColor}; height: ${height}px;">
  <div id="progressBar" style="width: ${width}%; height: ${height}px; background-color: ${fillColor}; ${animate ? `transition: width ${animationDuration} ${animationTimingFunction} ${animationDelay};` : ''}"></div>
  ${showPercentage ? `<span class="percentage">${width}%</span>` : ''}</div>
      `;
    }
  };

  

  const radius = height;
  const circumference = 2 * Math.PI * radius;

  return (
    <div>
      <header>
      <img src="/img/progress-bar-generator-logo.png" className="logo" alt="Progress Bar Generator Logo" />
      <a href="/" className="logo-title">Progress Bar Generator</a>
          <nav className="menu">
              <Navigation />
          </nav>
      </header>
  <div className="container">
    <div className="controls-container">
        <h1>Progress Bar Generator</h1>
        <h2 id="circular-title">Generate a CSS Progress Bar or a CSS Circular Progress Bar</h2>
        <div className="controls">
          <span className="controls-title">Select a Width and Height </span>
          <label>
            Width(%):
            <input 
              type="number" 
              value={width} 
              onChange={(e) => setWidth(e.target.value)} 
              min="0" 
              max="100" 
            />
          </label>

          <label>
            Height(px): 
            <input 
              type="number" 
              value={height} 
              onChange={(e) => setHeight(e.target.value)} 
              min="1" 
            />
          </label>

          <span className="controls-title">Choose a Color</span>
          <label>
            Background Color:
            <input 
              type="color" 
              value={bgColor} 
              onChange={(e) => setBgColor(e.target.value)} 
            />
          </label>

          <label>
            Fill Color:
            <input 
              type="color" 
              value={fillColor} 
              onChange={(e) => setFillColor(e.target.value)} 
            />
          </label>

          <span className="controls-title">Select an Option and Customize Progress Bar (Optional)</span>
          <label>
            Show Animation:
            <input 
              type="checkbox" 
              checked={animate} 
              onChange={(e) => setAnimate(e.target.checked)} 
            />
          </label>

          <div className={`animation-controls ${animate ? 'visible' : ''}`}>
            <label>
              Duration(s):
              <input 
                type="text" 
                value={animationDuration} 
                onChange={(e) => setAnimationDuration(e.target.value)} 
              />
            </label>

            <label>
              Timing Function:
              <input 
                type="text" 
                value={animationTimingFunction} 
                onChange={(e) => setAnimationTimingFunction(e.target.value)} 
              />
            </label>

            <label>
              Delay(s):
              <input 
                type="text" 
                value={animationDelay} 
                onChange={(e) => setAnimationDelay(e.target.value)} 
              />
            </label>
          </div>

          <label>
            Show Percentage:
            <input 
              type="checkbox" 
              checked={showPercentage} 
              onChange={(e) => setShowPercentage(e.target.checked)} 
            />
          </label>

          <label>
            Show Circular Progress Bar:
            <input 
              type="checkbox" 
              checked={circular} 
              onChange={(e) => setCircular(e.target.checked)} 
            />
          </label>
        </div>

        <div className="preview">
          <h3>Preview</h3>
          {!circular ? (
            <div id="progressContainer" style={{ backgroundColor: bgColor, height: `${height}px` }}>
              <div id="progressBar" 
                  style={{ 
                    width: `${width}%`, 
                    height: `${height}px`, 
                    backgroundColor: fillColor, 
                    transition: animate ? `width ${animationDuration} ${animationTimingFunction} ${animationDelay}` : 'none' 
                  }}>
              </div>
              {showPercentage && <span className="percentage">{width}%</span>}
            </div>
          ) : (
            <div id="circularContainer" style={{ width: `${height * 2}px`, height: `${height * 2}px`, backgroundColor: bgColor }}>
            <svg id="circularSVG" width={height * 2} height={height * 2}>
              <circle id="circularBackground" cx={height} cy={height} r={radius} />
              <circle id="circularBar" cx={height} cy={height} r={radius}
                      style={{
                        strokeDasharray: circumference,
                        strokeDashoffset: circumference - (width / 100) * circumference,
                        transition: animate ? `stroke-dashoffset ${animationDuration} ${animationTimingFunction} ${animationDelay}` : 'none'
                      }} />
            </svg>
            {showPercentage && <span className="circularPercentage">{width}%</span>}
          </div>
        )}
      </div>

        <div className="code">
          <h3>Copy the CSS Code Below</h3>
          <pre>{generateCSS()}</pre>
        </div>
        <div className="code">
          <h3>Copy the HTML Code Below</h3>
        <pre>{generateHTML()}</pre>
      </div>

    </div>
  </div>

  
 
  <Footer />
</div>
  );
}

export default Home;